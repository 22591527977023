<template>
  <div class="noroot">
    <div style="height:120px;width:1200px;display: flex;flex-direction:row;justify-content:space-between;align-items:center;margin:0 auto ">
        <div>
          <router-link to="/" class="home" style="display: flex;flex-direction:row;">
            <img :src="logo" class="logo" style="width:58px"/>
            <span  style="color: #000;">
              <h3>中医临床药学服务<br />与调剂教育平台</h3>
            </span>
          </router-link>
        </div>
        <div style="width:100px;height:40px;text-align:center;line-height:40px;background:#FF9552;color:white;border-radius:20px">
          <router-link to="/">返回首页</router-link>
        </div>
    </div>
    <div class="page">
      <img style="position:absolute;min-height:588px;width:100%;min-width:1280px" :src="require('../assets/login.jpg')"  />
      <div class="wrap" style="position: relative; margin: 0 auto">
        <!-- 网站标题 -->
        <!-- <div class="title">
          <img :src="logo" class="title-logo" />
          <span class="title-text">
            <h2>中医临床药学服务</h2>
            <h2>与调剂教育辅助平台</h2>
          </span>
        </div> -->
        <!-- 注册框 -->
        <div class="modal">
          <!-- 注册登录-->
          <!-- 手机号注册 -->
          <div v-if="telPage" class="telModal">
            <span class="modal-title">
              <p>注册账号</p>
            </span>
            <div class="tel-err" v-if="telErr">
              <img :src="telError" class="telErr" />
              手机号格式错误
            </div>
            <div class="tel-inputTel" style="position:relative">
              <div class="tel-tip">
                <input
                  type="text"
                  placeholder="+86"
                  disabled
                  autocomplete="off"
                />
              </div>
              <div class="tel-tel">
                <img :src="iphone" class="iphone" />
                <input
                  id="iPhone"
                  type="text"
                  placeholder="请输入手机号"
                  v-model="userTel"
                  autocomplete="off"
                  @focus="highLight"
                  @blur="userBlur"
                />
              </div>
              <div class="codeErr" v-if="resErr" style="position: absolute;color: #2c2c2c;width: 250px;top:60%;left:1%;font-size:12px">
              该手机号已注册，你可以<router-link to="/login" style="color: #FF9552">直接登录</router-link>
            </div>
            </div>
            
            <!-- 滑块 -->
            <div id="drag">
              <div class="drag_bg"></div>
              <div
                class="drag_text"
                onselectstart="return false;"
                unselectable="on"
              >
                请拖动滑块，拖到最右边
              </div>
              <div class="handler handler_bg"></div>
            </div>
            <!-- 获取验证码 -->
            <div class="verity" v-if="dragStatus && !resErr && !telErr && userTel.length>10">
              <div class="verity-num">
                <input
                  type="text"
                  placeholder="请输入验证码"
                  v-model="validCode"
                  autocomplete="off"
                  @focus="highLight"
                  @blur="normal"
                />
              </div>
              <div>
                <button
                  @click="getCode"
                  :disabled="disabled"
                  class="verity-btn"
                  style="cursor: pointer;"
                >
                  {{ text }}
                </button>
              </div>
              <div class="codeErr" v-if="codeErr" style="width:100%">{{errMsg}}</div>
            </div>
            
            <!-- 设置登录密码 -->
            <div class="pwd">
              <input
                :type="`${imgShow ? 'password' : 'text'}`"
                maxlength="16"
                minlength="6"
                placeholder="请设置登录密码"
                v-model="password"
                autocomplete="off"
                @keyup="pwdNull"
                @focus="highLight"
                @blur="pwdNull"
              />
              <img
                :src="`${imgShow ? `${eyeClose}` : `${eyeOpen}`}`"
                class="pwdImg"
                @click="pwdShow"
              />
            </div>

            <button @click="login" @mousedown="btnstyle" @mouseup="delBtnStyle" type="button" :style="`background:${btnStatus ? '#ddd': '#FF9552'};letter-spacing: 2px;margin-bottom:50px;cursor: pointer;`" :disabled="btnStatus">注册</button>
          </div>
          <!-- 其他登录方式 -->
          <div class="other">
            <div class="footer">
              <div class="div-checked">
                <input type="checkbox" v-model="warning" style="cursor: pointer;"/>
                <label
                  >勾选代表你同意
                  <router-link to="/copyright" target="_blank" class="copy" 
                    >《版权声明》</router-link
                  >
                  <router-link to="/privacy" target="_blank" class="privacy"
                    >《隐私声明》</router-link
                  >
                </label>
              </div>
              <div class="isHas">
                <span>已有账号？</span>
                <router-link to="/login" style="color: #FF9552"
                  >立即登录</router-link
                >
              </div>
            </div>
          </div>
          <!-- Warning -->
          <div v-if="warningMsg" class="div-warn">
            <div class="warning">
              <span>{{ msg }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { post } from "@/utils/common";
import router from "@/router";
import { initLogindata } from "@/utils/common";

export default {
  name: "Register",
  components: {},
  setup(props) {
    const state = reactive({
      resizetime: null,
      // login: require("../assets/login.jpg"),
      logo: require("../assets/logo3.png"),
      user: require("../assets/user.png"),
      pwd: require("../assets/pwd.png"),
      tel: require("../assets/tel.png"),
      user2: require("../assets/user2.png"),
      iphone: require("../assets/iphone.png"),
      telError: require("../assets/telErr.png"),
      verity1: require("../assets/verity1.png"),
      verity2: require("../assets/verity2.png"),
      eyeClose: require("../assets/eyeClose.png"),
      eyeOpen: require("../assets/eyeOpen.png"),

      warningMsg: false,
      warning: false,
      telPage: true,
      primary: false,
      telErr: false,
      unRegister: false,
      resErr: false,
      orErr: false,
      userTel: "",
      validCode: "",
      username: "",
      password: "",
      errMsg:"",
      codeErr: false,
      dragStatus: false,
      msg: "",
      text: "获取验证码",
      disabled: false,
      imgShow: true,
      btnStatus: true,
    });

    onMounted(() => {
      console.log("111");
      drag();
    });
    // 判断手机号格式&&判断手机号是否注册
    const userBlur = () => {
      var phone = document.getElementById("iPhone");
      var str = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (state.userTel.length){
        if (str.test(phone.value) == false) {
           state.telErr = true;
           state.btnStatus =true;
           state.resErr = false;
         } else {
            state.telErr = false;
            // state.btnStatus = false;
            post("checkRegister", { username: state.userTel }, (_, res) => {
          if (res.body.feedbackMsg == false) {
            state.resErr = false;
          } else {
            state.resErr = true;
          }
        });
        }      
      }
    };

    // 手机验证码登录
    const NotionModal = (msg) =>{
      state.warningMsg = true;
      state.msg = msg ;
      setTimeout(function () {
        state.warningMsg = false;
      }, 2000);
    }
    const login = () => {
      // 手机号和验证码非空判断
      if (!state.userTel.length) {
        NotionModal("请输入手机号！");
        return;
      }
      if (!state.validCode.length) {
        NotionModal("请输入验证码！");
        return;
      }
      if (!state.password.length) {
        NotionModal("请输入密码！");
        return;
      }
        
        //滑块是否通过
      if (!state.dragStatus) {
        NotionModal("请验证滑块！");

        return;
      }
       
      post(
        "authByVCode",
        {
          userTel: state.userTel,
          validCode: state.validCode,
          password: state.password,
        },
        (_, res) => {
           //是否勾选协议
          if (!state.warning) {
            NotionModal('请同意并勾选《版权声明》和《隐私声明》');
            return;
          }
          initLogindata(res.body);
          // console.log("dadadada", res.body.token.body);
          router.push({
            path: "/",
          });
        },err => {
          //验证码是否正确
          if (err) {
            state.errMsg = err;
            state.codeErr = true;
            return;
          } else {
            state.codeErr = false;
          }
        }
      );
      
    };

    // 滑块
    const drag = () => {
      console.log("telPagetelPagetelPage222");
      if (!state.telPage) return;
      console.log("telPagetelPagetelPage555");
      var x,
        dragdom = document.getElementById("drag"),
        isMove = false;
      //添加背景，文字，滑块
      // var html = '<div class="drag_bg"></div>'+
      //             '<div class="drag_text" onselectstart="return false;" unselectable="on">请拖动滑块，拖到最右边</div>'+
      //             '<div class="handler handler_bg"></div>';
      // this.append(html);
      var handler = dragdom.getElementsByClassName("handler")[0];
      var drag_bg = dragdom.getElementsByClassName("drag_bg")[0];
      var text = dragdom.getElementsByClassName("drag_text")[0];
      var maxWidth = (dragdom.clientWidth || 0) - (handler.clientWidth || 0); //能滑动的最大间距

      //鼠标按下时候的x轴的位置
      handler.addEventListener("mousedown", function (e) {
        console.log("mousedown");
        if (text.innerHTML === "验证通过") return;
        isMove = true;
        x = e.pageX - parseInt(handler.style.left || 0, 10);
      });

      //鼠标指针在上下文移动时，移动距离大于0小于最大间距，滑块x轴位置等于鼠标移动距离
      document.addEventListener("mousemove", function (e) {
        if (isMove) {
          var _x = e.pageX - x;
          if (_x > 0 && _x <= maxWidth) {
            handler.style.left = `${_x}px`;
            drag_bg.style.width = `${_x}px`;
          } else if (_x > maxWidth) {
            //鼠标指针移动距离达到最大时清空事件
            handler.style.left = `${maxWidth}px`;//当鼠标加速时把移动条置满
            drag_bg.style.width = `${maxWidth}px`;
            state.dragStatus = true;
            dragOk();
          }
        }
      });
      document.addEventListener("mouseup", function (e) {
        if (isMove) {
          isMove = false;
          var _x = e.pageX - x;
          if (_x < maxWidth) {
            //鼠标松开时，如果没有达到最大距离位置，滑块就返回初始位置
            handler.style.left = `0px`;
            drag_bg.style.width = `0px`;
          } else {
          }
        }
      });

      //清空事件
      function dragOk() {
        isMove = false;
        handler.classList.remove("handler_bg");
        handler.classList.add("handler_ok_bg");
        text.innerHTML = "验证通过";
        dragdom.style.color = `#fff`;
        handler.removeEventListener("mousedown", drag, false);
        document.removeEventListener("mousemove", drag, false);
        document.removeEventListener("mouseup", drag, false);
      }
    };

    //获取验证码
    const getCode = () => {
      post("getVCode", { userTel: state.userTel }, (_, res) => {
        state.disabled = true;
        let num = 60;
        state.text = `${num}s后重新获取`;
        let timer = setInterval(() => {
          num--;
          if (num > -1) {
            state.text = `${num}s后重新获取`;
          } else {
            clearInterval(timer);
            state.disabled = false;
            state.text = "获取验证码";
          }
        }, 1000);
        if(state.dragStatus && !state.resErr && !state.telErr) {
                state.btnStatus=false;
            }
      },err => {
        if (err) {
          state.warningMsg = true;
          state.msg = err;
          setTimeout(function () {
            state.warningMsg = false;
          }, 2000);
          return;
        }
      });
    };
    // 判断密码不为空
    const pwdNull =() => {
      if(state.password.length){

      }
    }

    // 注册密码点击显示
    const pwdShow = () => {
      state.imgShow = !state.imgShow;
    };

    const highLight= (e) => {
      // console.log("highLight ==", e);
      // e.target.parentNode.style.border = `1px solid rgb(255, 149, 82,0.5)`;
      // e.target.parentNode.style.boxShadow = `#ff9552 0px 0px 4px 1px`

    }

    const normal = (e) => {
      // e.target.parentNode.style.border = `2px solid #eee`;
      // e.target.parentNode.style.boxShadow = `none`
    }

    const btnstyle= (e) => {
      console.log("btnstyle ==", e);
      e.target.style.boxShadow = `0 0 3px 2px rgba(255, 149, 82, 0.3)`;
      

    }

    const delBtnStyle = (e) => {
      console.log("delBtnStyle ==", e);
      e.target.style.boxShadow = `none`;
    }

    return {
      ...toRefs(state),
      drag,
      //   onBlur,
      getCode,
      //   userLogin,
      login,
      userBlur,
      pwdShow,
      pwdNull,
      highLight,
      normal,
      btnstyle,
      delBtnStyle,
    };
  },
};
</script>


<style lang="less" scoped>
//空白背景
.noroot {
  height: 960px;
  position: relative;
}
//橘色背景
.page {
  height: 660px;
  // top: 140px;
  position: relative;
  // background-image: url("../assets/login.jpg");
}
.title {
  position: absolute;
  color: #fff;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  left: 170px;
  top: 60px;
}

.title-logo {
  width: 80px;
  height: 80px;
  // margin-top: 10px ;
}

.modal {
  width: 500px;
  height: auto;
  position: absolute;
  background: #fff;
  right: -10px;
  top: 74px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-title {
  margin: 5%;
  text-align: center;
  font-size: 20px;
}

.input-info,
button,
.tel-inputTel,
#drag,
.verity,.pwd{
  border-radius: 4px;
  margin-left: 110px;
  font-size: 14px;
}

.input-info ,.pwd{
  border: 2px solid #eee;
  width: 280px;
  height: 36px;
  margin-bottom: 30px;
}

.input-info > input {
  // border: 2px solid #ddd;
  margin-left: 2%;
  height: 36px;
  width: 240px;
}

button {
  width: 284px;
  height: 36px;
  color: #fff;
  background: #FF9552;
}

.user {
  width: 18px;
  height: 18px;
  margin: 5px;
  opacity: 0.7;
}

.pwd {
  width: 30px;
  height: 30px;
}

.forget-pwd {
  color: grey;
  font-size: 14px;
  display: block;
  margin: 15px 0px 15px 320px;
}

.hr {
  color: grey;
  border: 0;
  font-size: 14px;
  padding: 10px 0;
  position: relative;
  margin-top: 15px;
}

.hr::before {
  content: "其他登录方式";
  position: absolute;
  padding: 0 10px;
  line-height: 2px;
  border: solid #eee;
  border-width: 0 170px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.option {
  display: flex;
  flex-direction: row;
  margin: 30px 146px;
  font-size: 14px;
}

.tel,
.user2 {
  width: 30px;
  height: 30px;
  margin-right: 9px;
}

.mid {
  border-right: 2px solid #eee;
  margin: 0 32px;
}
.left {
  margin-right: 3px;
}

.footer {
  background: #eee;
  height: 25px;
  font-size: 14px;
  color: grey;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  margin-top: 25px;
}

.div-checked {
  // font-size: 12px;
  margin-right: 20px;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-left: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 18px;
  position: relative;
}

input[type="checkbox"]:checked::before {
  content: "\2714";
  background: #FF9552;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 4px;
}

.isHas {
  padding-left: 25px;
}
router-link:hover {
  color: #FF9552;
}

// 手机号登录
.iphone {
  width: 25px;
}
.tel-inputTel {
  display: flex;
  font-display: row;
}
.tel-tip,
.tel-tel,
.verity-num {
  border: 2px solid #eee;
  // width: 300px;
  height: 36px;
  margin-bottom: 30px;
  > input {
    margin-left: 5px;
    height: 36px;
  }
}
.tel-tip {
  width: 60px;
  margin-right: 10px;
  > input {
    margin: 0;
    width: 60px;
  }
}
.tel-tel {
  width: 206px;
  > input {
    margin: 0;
    width: 180px;
  }
}
//滑块
#drag {
  position: relative;
  background: #e8e8e8;
  width: 284px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 30px;
}
#drag .handler {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 37px;
  border: 1px solid #ccc;
  cursor: move;
}

.handler_bg {
  background: #fff url("../assets/dragR.png") no-repeat;
  background-size: 25px;
  background-position: 50% 50%;
}

.handler_ok_bg {
  background: #fff url("../assets/drag1.png") no-repeat;
  background-size: 30px;
  background-position: 50% 50%;
}

#drag .drag_bg {
  background: #7ac23c;
  height: 38px;
  width: 0px;
}

#drag .drag_text {
  position: absolute;
  top: 0px;
  width: 284px;
  user-select: none;
}
//验证码
.verity {
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  position: relative;
}
.verity-btn {
  width: 100px;
  margin-left: 10px;
  height: 36px;
  background: #FF9552;
  cursor: pointer;
}
.verity-num {
  margin-bottom: 0px;
  width: 170px;
  > input {
    width: 160px;
  }
}
// .verity-img {
//   // border: #ccc 1px solid;
//   width: 34px;
//   height: 34px;
//   margin-left: 8px;
//   // background-image: url("../assets/verity1.png");
//   background-size: 30px;
//   background-position: 50% 50%;
// }
//warnijng
.div-warn {
  position: absolute;
  width: 280px;
  height: 4vh;
  top: 40%;
  left: 18%;
  color: #fff;
  font-size: 14px;
  background: #000;
  opacity: 0.7;
  z-index: 100;
  border-radius: 4px;
  text-align: center;
  padding: 4vh 1vw;
}
//telErr
.tel-err {
  border: 2px solid rgb(247, 118, 118);
  width: 280px;
  height: 34px;
  margin: 0 0 15px 110px;
  border-radius: 4px;
  font-size: 14px;
  background: rgb(255, 218, 218);
  line-height: 2rem;
  // opacity: 0.7;
}
.telErr {
  width: 20px;
  margin: 0 5px;
}
//codeErr
.codeErr {
  font-size: 14px;
  color: red;
  width: 70px;
  height: 16px;
  position: absolute;
  top: 104%;
  left: 1%;
}
.copy:hover {
  color: #FF9552;
}
.privacy:hover {
  color: #FF9552;
}
//设置密码
.pwdImg {
  width: 20px;
}
.pwd {
  height: 36px;
  width: 280px;
  margin-left: 110px;
  > input {
    margin-left: 5px;
    width: 245px;
    height: 36px;
  }
}
</style>